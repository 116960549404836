import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useAuthState } from '../contexts/AuthContext';
import Loader from './shared/Loader';

import './PasswordLogin.scss';

/**
 * Really simple login error component
 */
const LoginError = props => {
  if (!props.children) {
    return null;
  }

  return <p className="LoginError">{props.children}</p>;
};

LoginError.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

/**
 * Component for rendering the password login.
 */
function PasswordLogin(props) {
  const { authenticate, isLoading, isError } = useAuthState();
  const [password, setPassword] = useState('');
  const { username } = props;

  if (isLoading) {
    return <Loader />;
  }

  // Right now only supports single error message
  const errorMsg = isError
    ? 'Felaktig lösenkod, kontrollera att du fyllt i korrekt lösenkod för ditt konto.'
    : null;

  // Render the password login
  return (
    <div className="wrapper">
      <form
        className="PasswordLogin"
        autoComplete="off"
        onSubmit={event => {
          authenticate(username, password);
          event.preventDefault();
        }}
      >
        <h2 className="PageTitle">Ange din 4 siffriga lösenkod</h2>
        <LoginError>{errorMsg}</LoginError>
        <div className="PasswordLoginWrapper">
          <input
            type="password"
            id="password-login"
            name="password-login"
            required
            minLength="4"
            maxLength="4"
            size="4"
            pattern="[0-9]*"
            inputMode="decimal"
            autoComplete="off"
            onChange={event => {
              setPassword(event.target.value);
            }}
            className={!errorMsg ? '' : 'FieldError'}
            value={password}
          />
          <input type="submit" value="Logga in" className="primary-button" />
        </div>
        <p>
          <Link to={`/logga-in/pin/${username}/ny`}>
            Glömt din personliga lösenkod?
          </Link>
          <br />
        </p>
        <p className="goback">
          <Link to="/logga-in">Tillbaka till användarna</Link>
        </p>
      </form>
    </div>
  );
}

PasswordLogin.propTypes = {
  username: PropTypes.string.isRequired
};

export default PasswordLogin;
