import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from './shared/Loader';

import './ForgotPasswordForm.scss';

/**
 * Component handles the render and requests for the user updating their password using "Forgot password" functionality
 */
function ForgotPasswordForm(props) {
  const { userLogin, resetKey } = props;

  // Some important states for the component
  const [password, setPassword] = useState('');
  const [isResetKeyValid, setResetKeyValid] = useState(false);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    /**
     * Initiates the request for validating the reset key.
     */
    function doValidateResetKey() {
      setIsLoading(true);
      setIsError(false);

      const { REACT_APP_API_URL } = process.env;

      // Function for validating reset key
      async function validateKey() {
        try {
          const result = await axios.get(
            `${REACT_APP_API_URL}/personalsnus/v1/reset-password/is-active?user=${userLogin}&key=${resetKey}&ignore`
          );

          if (result.data) {
            setResetKeyValid(true);
          }

          setIsLoading(false);
        } catch (error) {
          setIsError(true);
          setIsLoading(false);
        }
      }

      // Initiate the validation of the key
      validateKey();
    }

    // Initiate request
    doValidateResetKey();
  }, [userLogin, resetKey]);

  /**
   * Initiates the updatePassword() fetch request
   */
  function changePassword() {
    setErrorMessage(null);
    setIsLoading(true);
    setIsError(false);

    // Password length validation
    if (password.length < 4) {
      setIsError(true);
      setIsLoading(false);
      setErrorMessage('Du måste ha en PIN-kod som är minst 4 siffror lång.');
      return;
    }

    const { REACT_APP_API_URL } = process.env;

    // Function requests the API to update the users password
    async function updatePassword() {
      try {
        const result = await axios.put(
          `${REACT_APP_API_URL}/personalsnus/v1/reset-password/update?ignore`,
          {
            user: userLogin,
            key: resetKey,
            password
          }
        );

        if (result.data) {
          setIsPasswordUpdated(true);
        }

        setIsLoading(false);
      } catch (error) {
        setErrorMessage(
          'Det skedde ett fel i uppdateringen av din PIN-kod. Testa att byta PIN-kod vid ett senare tillfälle, om problemet kvarstår kontakta då en administratör.'
        );
        console.error(error);
        setIsError(true);
        setIsLoading(false);
      }
    }

    // Initiate the password update process
    updatePassword();
  }

  // Render the component
  if (isLoading && !isResetKeyValid) {
    return <Loader />;
  } else if (!isResetKeyValid) {
    return (
      <div className="ForgotPasswordForm">
        <div className="wrapper">
          <h2>Återställningsnyckeln är inte längre aktiv</h2>
          <p>
            Tyvärr har återställningsnyckeln gått ut, använts eller aldrig
            existerat. Du måste gå till{' '}
            <Link to={`/logga-in/pin/${userLogin}/ny`}>denna sida</Link>.
          </p>
        </div>
      </div>
    );
  } else if (!isPasswordUpdated) {
    return (
      <form
        className="ForgotPasswordForm"
        autoComplete="off"
        onSubmit={event => {
          changePassword();
          event.preventDefault();
        }}
      >
        <h2 className="PageTitle">Ny 4 siffrig lösenkod</h2>
        <div className="PasswordLoginWrapper">
          <input
            type="password"
            id="forgot-password"
            name="forgot-password"
            required
            minLength="4"
            maxLength="4"
            size="4"
            pattern="[0-9]*"
            inputMode="decimal"
            autoComplete="off"
            onChange={event => {
              setPassword(event.target.value);
            }}
            value={password}
          />
          <input
            type="submit"
            value="Uppdatera"
            disabled={isLoading}
            className="primary-button"
          />
        </div>
        {isError && <p>{errorMessage}</p>}
      </form>
    );
  } else {
    return (
      <div className="ForgotPasswordForm">
        <div className="wrapper">
          <h2>Din 4 siffriga lösenkod uppdaterades</h2>
          <p>
            Gå{' '}
            <Link to={`/logga-in/pin/${userLogin}`}>till lösenordssidan</Link>{' '}
            för att logga in med din nya kod.
          </p>
        </div>
      </div>
    );
  }
}

ForgotPasswordForm.propTypes = {
  userLogin: PropTypes.string.isRequired,
  resetKey: PropTypes.string.isRequired
};

export default ForgotPasswordForm;
