import React from 'react';
import PropTypes from 'prop-types';

import SitePasswordLogin from '../components/SitePasswordLogin';

function SitePassword() {
  return (
    <div className="SitePassword">
      <SitePasswordLogin />
    </div>
  );
}

SitePassword.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SitePassword;
