import React, { createContext, useState, useContext } from 'react';

export const FeedbackContext = createContext([]);

export default function FeedbackProvider(props) {
  const [hasFeedback, setHasFeedback] = useState(true);

  const values = {
    hasFeedback,
    setHasFeedback
  };

  return (
    <FeedbackContext.Provider value={values}>
      {props.children}
    </FeedbackContext.Provider>
  );
}

export const FeedbackConsumer = FeedbackContext.Consumer;
export const useFeedback = () => useContext(FeedbackContext);
