import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useAuthState } from '../contexts/AuthContext';
import Loader from './shared/Loader';

import './SitePasswordLogin.scss';

function sanitizePathname(pathname = '') {
  if (pathname === null || pathname === undefined) {
    return '';
  }

  return pathname
    .trim()
    .replace(/^\/+|\/+$/g, '')
    .replace(/[&<>"']/g, function (match) {
      return {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
      }[match];
    });
}

/**
 * Really simple login error component
 */
const LoginError = (props) => {
  if (!props.children) {
    return null;
  }

  return <p className="LoginError">{props.children}</p>;
};

LoginError.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

/**
 * Component for rendering the password login.
 */
function SitePasswordLogin() {
  const { siteAuth, authSiteAccess, isLoading, isError } = useAuthState();
  const [sitePassword, setSitePassword] = useState('');

  if (isLoading) {
    return <Loader />;
  }

  const { search } = window.location;
  const requestedUrl = new URLSearchParams(search).get('requestedUrl');

  const invalidUrl =
    requestedUrl !== null
      ? !requestedUrl.startsWith('/') ||
        requestedUrl.includes('http://') ||
        requestedUrl.includes('https://')
      : true;

  if (siteAuth) {
    return (
      <Redirect
        to={
          !invalidUrl && requestedUrl
            ? sanitizePathname(requestedUrl)
            : '/logga-in'
        }
      />
    );
  }

  // Right now only supports single error message
  const errorMsg = isError
    ? 'Felaktigt lösenord! Du måste fylla i rätt lösenord för att få åtkomst till Personalsnus.'
    : null;

  // Render the password login
  return (
    <div className="wrapper">
      <form
        className="SitePasswordLogin"
        autoComplete="off"
        onSubmit={(event) => {
          authSiteAccess(sitePassword);
          event.preventDefault();
        }}
      >
        <h2 className="PageTitle">Ange lösenordet för hemsidan</h2>
        <LoginError>{errorMsg}</LoginError>
        <div className="SitePasswordLoginWrapper">
          <input
            type="password"
            id="site-password-login"
            name="site-password-login"
            required
            autoComplete="off"
            onChange={(event) => {
              setSitePassword(event.target.value);
            }}
            className={`site-password-login-field${
              !errorMsg ? '' : ' FieldError'
            }`}
            value={sitePassword}
          />
          <input type="submit" value="Logga in" className="primary-button" />
        </div>
      </form>
    </div>
  );
}

export default SitePasswordLogin;
