import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorDescription } from './shared/Styled';
import { useAuthState } from '../contexts/AuthContext';
import { useFeedback } from '../contexts/FeedbackContext';
import axios from 'axios';
import options from '../data/feedback.json';
import Loader from './shared/Loader';

import './FeedbackList.scss';

const FeedbackDescription = styled.p`
  font-size: 1.6rem;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
`;

const FeedbackFooter = styled.footer`
  max-width: 1180px;
  margin: 40px auto 0 auto;
  text-align: center;
`;

// Child component of FeedbackList, renders a single feedback item
function FeedbackItem(props) {
  const { setFeedback, feedbackItem, feedbackHasError } = props;
  const { selectedFeedback, otherMessage } = feedbackItem;

  // Handles the select field input changes
  function handleFeedbackChange(event) {
    const theFeedback = options.find(item => {
      return item.value === event.target.value;
    });

    let feedback = {
      ...feedbackItem,
      ...{ selectedFeedback: theFeedback }
    };

    // If the feedback value is NOT other, set the feedback.otherMessage string to empty
    if (theFeedback.value !== '12') {
      feedback.otherMessage = '';
    }

    setFeedback(feedback);
  }

  function handleOtherMsgChange(event) {
    const feedback = {
      ...feedbackItem,
      ...{ otherMessage: event.target.value }
    };
    setFeedback(feedback);
  }

  return (
    <div
      className={
        feedbackHasError ? 'FeedbackItem FeedbackItem-error' : 'FeedbackItem'
      }
    >
      <div className="item-wrapper">
        {feedbackItem.thumbnail_url ? (
          <img src={feedbackItem.thumbnail_url} alt={feedbackItem.title} />
        ) : null}
        <h2 className="FeedbackItem-title">
          <strong>{feedbackItem.product_group}</strong>
          {feedbackItem.product_name}
        </h2>
        <label>Upplevde du något fel med produkten?</label>
        <div className="select-field-wrapper">
          <select onChange={handleFeedbackChange}>
            {options.map(item => {
              return (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>
        {selectedFeedback && selectedFeedback.value === '12' ? (
          <label>
            Beskriv ditt fel
            <textarea value={otherMessage} onChange={handleOtherMsgChange} />
          </label>
        ) : null}
      </div>
    </div>
  );
}

FeedbackItem.propTypes = {
  setFeedback: PropTypes.func.isRequired,
  feedbackItem: PropTypes.object.isRequired,
  feedbackHasError: PropTypes.bool.isRequired
};

// Component which renders the whole feedback list
function FeedbackList() {
  const { user } = useAuthState();
  const { setHasFeedback } = useFeedback();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [itemErrors, setItemErrors] = useState([]);

  // After first render, we need to see if the user has not given feedback on a report (fetch that data)
  useEffect(() => {
    async function fetchData() {
      setIsError(false);
      setIsLoading(true);

      const { REACT_APP_API_URL } = process.env;

      try {
        const result = await axios.get(
          `${REACT_APP_API_URL}/personalsnus/v1/reports`,
          {
            headers: { Authorization: `Bearer ${user.token}` }
          }
        );

        if (result.data.length > 0) {
          setData(
            result.data.map(item => {
              return { ...item, selectedFeedback: null, otherMessage: '' };
            })
          );
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setHasFeedback(false);
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
        setIsLoading(false);
      }
    }

    fetchData();

    return () => {};
  }, [user, setHasFeedback]);

  // Handle the submition of the form data
  function handleSubmit(event) {
    event.preventDefault(); // Prevent page reload, let the function handle the submit

    const errors = getItemsWithErrors();
    if (errors.length > 0) {
      setItemErrors(errors);
      setIsError(true);
      // setIsLoading(false);
      return;
    }

    const feedbackData = data.map(item => {
      return {
        productId: item.id,
        productNr: item.product_number,
        countPrivate: item.private_amount,
        feedback: item.selectedFeedback.label,
        otherMessage: item.otherMessage
      };
    });

    // Set the different states
    setIsError(false);
    setIsLoading(true);

    const { REACT_APP_API_URL } = process.env;

    async function postItems() {
      try {
        const result = await axios.post(
          `${REACT_APP_API_URL}/personalsnus/v1/feedback`,
          {
            items: feedbackData
          },
          {
            headers: { Authorization: `Bearer ${user.token}` }
          }
        );

        setIsLoading(false);
        if (result.data) {
          setHasFeedback(false);
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
        setIsLoading(false);
      }
    }

    // Post the items data towards the API
    postItems();
  }

  // Validates and return items with errors
  function setFeedbackItem(updatedItem) {
    setData(
      data.map(item => {
        return item.id === updatedItem.id ? updatedItem : item;
      })
    );
  }

  // Validates and return items with errors
  function getItemsWithErrors() {
    return data.filter(item => {
      return !item.selectedFeedback;
    });
  }

  const FeedbackItems =
    data.length > 0
      ? data.map(item => {
          return (
            <FeedbackItem
              key={item.id}
              feedbackItem={item}
              setFeedback={setFeedbackItem}
              feedbackHasError={isError && itemErrors.includes(item)}
            />
          );
        })
      : null;

  if (isLoading) {
    return <Loader />;
  }

  return FeedbackItems && FeedbackItems.length > 0 ? (
    <div className="FeedbackItems">
      <div className="wrapper">
        {isError && itemErrors.length <= 0 ? (
          <h2>Det skedde ett fel i hämtningen av feedbacken</h2>
        ) : null}
        <form onSubmit={handleSubmit}>
          <h2 className="UserHi">Hej {user.first_name}!</h2>
          <h2 className="PageTitle">Feedback på snuset du tog sist</h2>
          <FeedbackDescription>
            Om du inte hunnit förbruka ditt snus från föregående rapportering,
            och i efterhand upptäcker ett kvalitetsproblem så behöver du maila{' '}
            <a href="mailto:Konsumentkontakt.Sweden@skruf.se">
              Konsumentkontakt.Sweden@skruf.se
            </a>
          </FeedbackDescription>
          <div className="FeedbackList">{FeedbackItems}</div>
          {isError && (
            <ErrorDescription>
              Det finns fel i din feedback, felen har markerats ovan.
            </ErrorDescription>
          )}
          <FeedbackFooter className="FeedbackFooter">
            <input
              type="submit"
              value="Skicka in"
              disabled={isLoading}
              className="primary-button"
            />
          </FeedbackFooter>
        </form>
      </div>
    </div>
  ) : null;
}

export default FeedbackList;
