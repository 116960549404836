import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { useAuthState } from '../contexts/AuthContext';
import UserList from '../components/UserList';
import PasswordLogin from '../components/PasswordLogin';

function Login({ match }) {
  const { userLogin } = match.params;
  const { isLoggedIn, siteAuth } = useAuthState();

  if (isLoggedIn) {
    return <Redirect to="/inloggad" />;
  } else if (!siteAuth) {
    const { location } = window;
    return <Redirect to={`/site-access?requestedUrl=${location.pathname}`} />;
  }

  if (userLogin) {
    return (
      <div className="Login">
        <PasswordLogin username={match.params.userLogin} />
      </div>
    );
  } else {
    return (
      <div className="Login">
        <UserList />
      </div>
    );
  }
}

Login.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Login;
