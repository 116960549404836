import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from './shared/Loader';

import './UserList.scss';

function UserItem(props) {
  const { displayName, userLogin } = props;
  return (
    <li className="UserItem">
      <Link to={`/logga-in/pin/${userLogin}`}>{displayName}</Link>
    </li>
  );
}

UserItem.propTypes = {
  userLogin: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired
};

function UserList() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filter, setFilter] = useState('subscriber');

  useEffect(() => {
    async function fetchData() {
      setIsError(false);
      setIsLoading(true);

      const { REACT_APP_API_URL } = process.env;
      try {
        const result = await axios(
          `${REACT_APP_API_URL}/personalsnus/v1/users`
        );
        if (result.data.length > 0) {
          setData(result.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const filters = [
    { title: 'Hk', value: 'subscriber' },
    { title: 'Sälj', value: 'seller' },
    { title: 'Ej lager', value: 'kam' }
  ];

  const UserFilters = filters.map((item, index) => {
    return (
      <li
        className={filter === item.value ? 'FilterItem active' : 'FilterItem'}
        key={index}
      >
        <button
          onClick={event => {
            setFilter(item.value);
            event.preventDefault();
          }}
        >
          {item.title}
        </button>
      </li>
    );
  });

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return (
      <>
        <h2>Något gick snett med hämtningen av användarna</h2>
        <p>Testa att ladda om sidan och se om problemet kvarstår.</p>
      </>
    );
  }

  const userItems = data
    .filter(item => {
      if (item.roles.length > 0 && filter !== '*') {
        return item.roles.includes(filter); // Show only the user which matches the user role
      } else {
        return true; // Show every user
      }
    })
    .sort((a, b) => {
      return a.display_name.localeCompare(b.display_name);
    })
    .map(item => (
      <UserItem
        key={item.ID}
        userLogin={item.user_login}
        displayName={item.display_name}
      />
    ));

  return (
    <div className="wrapper">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h2 className="PageTitle">Välj ditt namn</h2>
          <ul className="FilterList">{UserFilters}</ul>
          <ul className="UsersList">{userItems}</ul>
        </>
      )}
    </div>
  );
}

export default UserList;
