import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import ProductImage from './shared/ProductImage';
import Loader from './shared/Loader';
import { ErrorDescription } from './shared/Styled';

import { useCart } from '../contexts/CartContext';
import { useAuthState } from '../contexts/AuthContext';
import { useFeedback } from '../contexts/FeedbackContext';

import './Checkout.scss';

/**
 * Child component for the cart list, renders a single cart item.
 */
function CartItem(props) {
  const { itemData } = props;
  const { countPromo, countPrivate } = itemData;
  const { remove, setItemProperty } = useCart();

  /*
   * Callback functions which updates the cart context
   */

  function updatePromoCount(count) {
    const property = { countPromo: count };
    setItemProperty(itemData, property);
  }

  function updatePrivateCount(count) {
    const property = { countPrivate: count };
    setItemProperty(itemData, property);
  }

  function updateLagerId(lagerId) {
    setItemProperty(itemData, { lagerPrivate: lagerId });
    setItemProperty(itemData, { lagerPromo: lagerId });
  }

  // Some product img media
  const [attachment] = itemData['_embedded']['wp:featuredmedia'] || [];
  const imgSizes = ['thumbnail', 'medium', 'medium_large'];

  // Return the render of the cart item (this should probably be divided into several child components)
  return (
    <div
      className={
        props.itemHasError ? 'CartItem CartItem-has-errors' : 'CartItem'
      }
    >
      {attachment ? (
        <ProductImage
          alt={attachment.alt_text}
          src={attachment.media_details.sizes.thumbnail.source_url || ''}
          sizes={attachment.media_details.sizes}
          targetSizes={imgSizes}
        />
      ) : null}
      <div className="CartItem-fields">
        <h2 className="CartItem-title">
          <strong>{props.itemGroup}</strong>
          {props.itemName}
        </h2>
        <p>Ange antal dosor</p>
        <div className="CartItem-field">
          <label htmlFor={`CartItem-Amount-Private-${props.itemData.id}`}>
            Privat
          </label>
          <div className="CartItem-amount-inputs">
            <input
              type="number"
              value={countPrivate || ''}
              placeholder={0}
              min={0}
              max={999}
              id={`CartItem-Amount-Private-${props.itemData.id}`}
              onChange={event => {
                const count = parseInt(event.target.value, 10) || '';
                updatePrivateCount(count);
              }}
            />
            <button
              className="CartItem-amount-button"
              title="Subtrahera 1 på antalet"
              onClick={event => {
                event.preventDefault(); // Prevent form from submitting
                const count =
                  typeof countPrivate === 'number' && countPrivate > 0
                    ? countPrivate - 1
                    : 0;
                updatePrivateCount(count);
              }}
            >
              <svg className="icon icon-minus">
                <use xlinkHref="#icon-minus"></use>
              </svg>
            </button>
            <button
              className="CartItem-amount-button"
              title="Addera 1 på antalet"
              onClick={event => {
                event.preventDefault(); // Prevent form from submitting
                const count =
                  typeof countPrivate === 'number' ? countPrivate + 1 : 1;
                updatePrivateCount(count);
              }}
            >
              <svg className="icon icon-plus">
                <use xlinkHref="#icon-plus"></use>
              </svg>
            </button>
          </div>
        </div>
        <div className="CartItem-field">
          <label htmlFor={`CartItem-Amount-Promo-${props.itemData.id}`}>
            Promo
          </label>
          <div className="CartItem-amount-inputs">
            <input
              type="number"
              value={countPromo || ''}
              placeholder={0}
              min={0}
              max={999}
              id={`CartItem-Amount-Promo-${props.itemData.id}`}
              onChange={event => {
                const count = parseInt(event.target.value, 10) || '';
                updatePromoCount(count);
              }}
            />
            <button
              className="CartItem-amount-button"
              title="Subtrahera 1 på antalet"
              onClick={event => {
                event.preventDefault(); // Prevent form from submitting
                const count =
                  typeof countPromo === 'number' && countPromo > 0
                    ? countPromo - 1
                    : 0;
                updatePromoCount(count);
              }}
            >
              <svg className="icon icon-minus">
                <use xlinkHref="#icon-minus"></use>
              </svg>
            </button>
            <button
              className="CartItem-amount-button"
              title="Addera 1 på antalet"
              onClick={event => {
                event.preventDefault(); // Prevent form from submitting
                const count =
                  typeof countPromo === 'number' ? countPromo + 1 : 1;
                updatePromoCount(count);
              }}
            >
              <svg className="icon icon-plus">
                <use xlinkHref="#icon-plus"></use>
              </svg>
            </button>
          </div>
        </div>
        {props.isKAM ? (
          <div className="CartItem-field">
            <label>Välj lager</label>
            <div className="select-field-wrapper">
              <select
                value={itemData.lagerPrivate || ''}
                onChange={event => {
                  const value = event.target.value;
                  updateLagerId(value);
                }}
              >
                <option value=""></option>
                <>
                  <option value="104" key={104}>
                    Skruf Kyl
                  </option>
                  {props.lagerOptions.map(opt => {
                    return (
                      <option value={opt.employee_id} key={opt.ID}>
                        {opt.display_name}
                      </option>
                    );
                  }) || null}
                </>
              </select>
            </div>
          </div>
        ) : null}
        <div className="CartItem-actions">
          <button
            className="CartItem-remove-button"
            title="Ta bort produkten från varukorgen"
            onClick={event => {
              remove(props.itemData);
              event.preventDefault();
            }}
          >
            <svg className="icon icon-cross2">
              <use xlinkHref="#icon-cross2"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

CartItem.propTypes = {
  itemData: PropTypes.object.isRequired,
  itemGroup: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  itemHasError: PropTypes.bool.isRequired,
  isKAM: PropTypes.bool.isRequired,
  lagerOptions: PropTypes.array.isRequired
};

/**
 * Renders the whole CartList along with cartitems etc.
 */
function Checkout() {
  // Which contexts to consume
  const { items, destroyCart } = useCart();
  const { user, signOut } = useAuthState();
  const { setHasFeedback } = useFeedback();

  // Several states
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemErrors, setItemErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [lagerOptions, setLagerOptions] = useState([]);

  /**
   * Function filters through all cart items and validates the items, returns an collected error array.
   */
  function getItemsWithErrors() {
    // Validate all the amount inputs
    const amountErrors = items.filter(item => {
      return item.countPrivate <= 0 && item.countPromo <= 0;
    });

    // Validate all the lager inputs
    const lagerErrors = items.filter(item => {
      return user.roles.includes('kam') ? !item.lagerPrivate : false;
    });

    // Concatenate and merge the arrays into a single error array
    const concatErrors = [...amountErrors, ...lagerErrors];
    const errors = Array.from(new Set([...concatErrors]));

    return errors;
  }

  /**
   * Function which handles the cart list form submit and post request to the API.
   * @param {*} event
   */
  function handleSubmit(event) {
    // Prevent page reload upon submit
    event.preventDefault();

    // Check & get all cart items with errors
    const errors = getItemsWithErrors();
    if (errors.length > 0) {
      setItemErrors(errors);
      setIsError(true);
      setIsLoading(false);
      return;
    }

    const itemData = items.map(item => {
      // If the  user is an subscriber = works in the office and takes products from the refrigerator (ID: 104)
      // If the user is an seller = it always counts as taking products from their own storage (their own employee ID)
      // If the user is an key account manager (kam) = they can select which storage they can take from (select employee ID)
      const lagerId = user.roles.includes('subscriber')
        ? 104
        : user.employee_id;
      return {
        productId: item.id,
        productNr: item.product_nr,
        countPrivate: item.countPrivate,
        countPromo: item.countPromo,
        employeeId: user.employee_id,
        lagerPrivate: user.roles.includes('kam') ? item.lagerPrivate : lagerId,
        lagerPromo: user.roles.includes('kam') ? item.lagerPromo : lagerId
      };
    });

    // Set the different states
    setIsError(false);
    setIsLoading(true);

    const { REACT_APP_API_URL } = process.env;
    async function postItems() {
      try {
        const result = await axios.post(
          `${REACT_APP_API_URL}/personalsnus/v1/reports`,
          {
            items: itemData
          },
          {
            headers: { Authorization: `Bearer ${user.token}` }
          }
        );
        if (result.data) {
          setIsLoading(false);
          setIsSubmitted(true);
          destroyCart();
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setIsError(true);
      }
    }

    // Post the items data towards the API
    postItems();
  }

  // Fetch the employee ID:s to use in the lager select input
  useEffect(() => {
    function fetchEmployees() {
      const { REACT_APP_API_URL } = process.env;
      return axios.get(`${REACT_APP_API_URL}/personalsnus/v1/employees`);
    }

    try {
      setIsLoading(true);
      setIsError(false);
      axios.all([fetchEmployees()]).then(
        axios.spread(employees => {
          setLagerOptions(employees.data);
          setIsLoading(false);
        })
      );
    } catch (error) {
      console.error(error);
      setIsError(true);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      signOut();
      setHasFeedback(true);
    }
  }, [isSubmitted, destroyCart, signOut, setHasFeedback]);

  if (isSubmitted) {
    return <Redirect to="/tack" />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form className="CartList-form" onSubmit={handleSubmit}>
      <div className="wrapper">
        {items.length <= 0 ? (
          <>
            <h2 className="PageTitle">Din varukorg är tom</h2>
            <Link to="/inloggad">Tillbaka till produkterna</Link>
          </>
        ) : (
          <>
            <h2 className="PageTitle">Din varukorg</h2>
            <div className="CartList">
              {items.map(item => {
                return (
                  <CartItem
                    key={item.id}
                    itemGroup={item.product_group}
                    itemName={item.product_name}
                    itemId={item.id}
                    itemData={item}
                    itemHasError={isError && itemErrors.includes(item)}
                    isKAM={user.roles.includes('kam') || false}
                    lagerOptions={lagerOptions}
                  />
                );
              })}
            </div>
            <footer className="CartList-footer">
              {isError && (
                <ErrorDescription>
                  Det skedde fel i formuläret, felen har markerats ovan.
                </ErrorDescription>
              )}
              <button
                type="submit"
                disabled={isLoading}
                className="primary-button"
              >
                Skicka in{' '}
                <svg className="icon icon-arrow-right">
                  <use xlinkHref="#icon-arrow-right"></use>
                </svg>
              </button>
            </footer>
          </>
        )}
      </div>
    </form>
  );
}

export default Checkout;
