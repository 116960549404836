import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useAuthState } from '../contexts/AuthContext';
import { useFeedback } from '../contexts/FeedbackContext';
import { useCart } from '../contexts/CartContext';

import './AppHeader.scss';
import logo from '../images/logo.svg';

function NavItem(props) {
  const { isNavLink } = props;
  const { wasAdded, items } = useCart();

  const navClasses = ['NavItem'];
  const cartItemCount = items.length || 0;

  if (props.isCart && wasAdded) {
    navClasses.push('item-added');
  }

  if (props.isCart && items.length > 0) {
    navClasses.push('cart-is-not-empty');
  }

  if (isNavLink) {
    return (
      <li className={navClasses.join(' ')}>
        <NavLink to={props.to} exact={props.exact}>
          {props.children}
          {props.isCart && (
            <span className="NavItem-cartcount">{cartItemCount}</span>
          )}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li className="NavItem">
        <Link to={props.to}>{props.children}</Link>
      </li>
    );
  }
}

function AppHeader() {
  const { isLoggedIn, signOut } = useAuthState();
  const { hasFeedback, setHasFeedback } = useFeedback();
  const { destroyCart } = useCart();

  // Simple function which clears the current users data...
  function clearUserData(event) {
    if (event.type === 'click') {
      setHasFeedback(true);
      destroyCart();
      signOut();
    } else if (event.type === 'keyPress' && event.key === 'Enter') {
      setHasFeedback(true);
      destroyCart();
      signOut();
    } else {
      // Do nothing!
    }
  }

  return (
    <header className="AppHeader">
      <div className="wrapper">
        <Link to="/" className="AppLogo">
          <img
            src={logo}
            alt="Personalsnus symbol"
            className="AppLogo-symbol"
          />
          personalsnus
        </Link>
        <nav className="AppNavigation">
          <ul className="AppMenu">
            {isLoggedIn ? (
              <>
                {!hasFeedback ? (
                  <>
                    <NavItem to="/inloggad" exact={true} isNavLink={true}>
                      Produkter
                      <svg className="icon icon-database">
                        <use xlinkHref="#icon-database"></use>
                      </svg>
                    </NavItem>
                    <NavItem
                      to="/inloggad/varukorg"
                      exact={true}
                      isNavLink={true}
                      isCart={true}
                    >
                      Varukorg
                      <svg className="icon icon-bag">
                        <use xlinkHref="#icon-bag"></use>
                      </svg>
                    </NavItem>
                  </>
                ) : null}
                <li className="NavItem">
                  <Link
                    to="/logga-ut"
                    onClick={clearUserData}
                    onKeyPress={clearUserData}
                  >
                    Logga ut
                    <svg className="icon icon-exit">
                      <use xlinkHref="#icon-exit"></use>
                    </svg>
                  </Link>
                </li>
              </>
            ) : (
              <NavItem to="/logga-in" isNavLink={false} exact={false}>
                Logga in
                <svg className="icon icon-enter">
                  <use xlinkHref="#icon-enter"></use>
                </svg>
              </NavItem>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default AppHeader;
