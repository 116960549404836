import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useCart } from '../contexts/CartContext';
import ProductImage from './shared/ProductImage';
import Loader from './shared/Loader';

import './ProductList.scss';
import { useAuthState } from '../contexts/AuthContext';

// Chiild component for Brands, handles the filter for the ProductList component
function FilterItem(props) {
  const { setFilter, currentFilter, item } = props;
  return (
    <li
      key={item.id}
      className={currentFilter === item.id ? 'FilterItem active' : 'FilterItem'}
    >
      <button
        onClick={event => {
          event.preventDefault();
          setFilter(item.id);
        }}
      >
        {item.name}
      </button>
    </li>
  );
}

FilterItem.propTypes = {
  setFilter: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

// Simple child component for adding / removing products from the cart context
function ProductItem(props) {
  const { add, remove, items } = useCart();
  const { itemData, children } = props;

  // Helper function to see if current product is within cart
  function inCart(item) {
    if (items.length > 0) {
      return items.some(p => {
        return p.id === item.id;
      });
    } else {
      return false;
    }
  }

  const [attachment] = itemData['_embedded']['wp:featuredmedia'] || [];
  const imgSizes = ['thumbnail', 'medium', 'medium_large'];

  return (
    <div
      className="ProductItem"
      onClick={() => {
        if (!inCart(itemData)) {
          add(itemData);
        } else {
          remove(itemData);
        }
      }}
    >
      <div className="item-wrapper">
        {attachment ? (
          <ProductImage
            alt={attachment.alt_text}
            src={attachment.media_details.sizes.thumbnail.source_url || ''}
            sizes={attachment.media_details.sizes}
            targetSizes={imgSizes}
          />
        ) : null}
        {children}
        {!inCart(itemData) ? (
          <button
            className="ProductItem-button button-cart-add"
            onClick={event => {
              event.stopPropagation();
              add(itemData);
            }}
          >
            <svg className="icon icon-plus">
              <use xlinkHref="#icon-plus"></use>
            </svg>
          </button>
        ) : (
          <button
            className="ProductItem-button button-cart-remove"
            onClick={event => {
              event.stopPropagation();
              remove(itemData);
            }}
          >
            <svg className="icon icon-minus">
              <use xlinkHref="#icon-minus"></use>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

ProductItem.propTypes = {
  children: PropTypes.element,
  itemData: PropTypes.object.isRequired
};

function ProductList() {
  const [stateProducts, setProducts] = useState([]);
  const [stateBrands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filter, setFilter] = useState(2); // 2 = skruf brand
  const { items } = useCart();
  const { user } = useAuthState();

  // After first render, fetch brands and products to populate in the states
  useEffect(() => {
    function fetchBrands() {
      const { REACT_APP_API_URL } = process.env;
      return axios.get(
        `${REACT_APP_API_URL}/wp/v2/trademark?hide_empty=1&orderby=menu_order`
      );
    }

    function fetchData() {
      const { REACT_APP_API_URL } = process.env;
      return axios.get(
        `${REACT_APP_API_URL}/wp/v2/products?_embed&per_page=100&orderby=menu_order`
      );
    }

    setIsLoading(true);
    setIsError(false);

    try {
      axios.all([fetchBrands(), fetchData()]).then(
        axios.spread((brands, products) => {
          setProducts(products.data);
          setBrands(brands.data);
          setIsLoading(false);
        })
      );
    } catch (error) {
      console.error(error);
      setIsError(true);
      setIsLoading(false);
    }
  }, []);

  // Map through the filter items to create the brand filter components
  const FilterItems =
    stateBrands.length > 0
      ? stateBrands.map(item => {
          return (
            <FilterItem
              key={item.id}
              item={item}
              setFilter={setFilter}
              currentFilter={filter}
            />
          );
        })
      : null;

  // Map through the product items to create the product item components for the cart
  const ProductItems =
    stateProducts.length > 0
      ? stateProducts
          .filter(item => {
            return filter === '*' || item.trademark.includes(filter);
          })
          .map(item => {
            return (
              <ProductItem key={item.id} itemData={item}>
                <h2>
                  <strong>{item.product_group}</strong>
                  {item.product_name}
                </h2>
              </ProductItem>
            );
          })
      : null;

  return (
    <div className="wrapper">
      {isError && <h2>Det skedde ett fel i hämtningen av produkterna</h2>}
      {isLoading && <Loader />}
      {FilterItems && ProductItems ? (
        <>
          <h2 className="UserHi">Hej {user.first_name}!</h2>
          <ul className="FilterList">{FilterItems}</ul>
          <div className="ProductList">{ProductItems}</div>
          <footer className="ProductList-footer">
            {items.length > 0 ? (
              <Link to="/inloggad/varukorg" className="primary-button">
                Se din varukorg
                <svg className="icon icon-bag">
                  <use xlinkHref="#icon-bag"></use>
                </svg>
              </Link>
            ) : (
              <>
                <h2>Din varukorg är tom</h2>
              </>
            )}
          </footer>
        </>
      ) : null}
    </div>
  );
}

export default ProductList;
