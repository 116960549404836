import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState
} from 'react';

export const CartContext = createContext([]);

function reducer(state, action) {
  const { item } = action;
  switch (action.type) {
    case 'ADD_ITEM':
      return [...state, item];

    case 'REMOVE_ITEM':
      return state.filter(product => {
        return product.id !== item.id;
      });

    case 'DESTROY_CART':
      return [];

    case 'UPDATE_ITEM_PROPERTY':
      const { updatedProperty } = action;
      return state.map(product => {
        if (product.id === item.id) {
          return { ...product, ...updatedProperty };
        } else {
          return product;
        }
      });
    default:
      throw new Error();
  }
}

export default function CartProvider(props) {
  const cachedItems = JSON.parse(sessionStorage.getItem('cart-items')) || [];
  const [state, dispatch] = useReducer(reducer, cachedItems);
  const [wasAdded, setWasAdded] = useState(false);

  const removeItem = item => {
    dispatch({ type: 'REMOVE_ITEM', item });
  };

  const addItem = itemToAdd => {
    console.log('adding item...');
    const item = {
      ...itemToAdd,
      countPrivate: 0,
      countPromo: 0,
      lagerPrivate: null,
      lagerPromo: null
    };
    dispatch({ type: 'ADD_ITEM', item });

    // Upon addItem, set was added boolean also (will trigger some animations)
    if (!wasAdded) {
      setWasAdded(true);
      setTimeout(() => {
        setWasAdded(false);
      }, 1000);
    }
  };

  const setItemProperty = (item, updatedProperty) => {
    dispatch({ type: 'UPDATE_ITEM_PROPERTY', item, updatedProperty });
  };

  const destroyCart = () => {
    dispatch({ type: 'DESTROY_CART' });
  };

  const values = {
    add: addItem,
    remove: removeItem,
    setItemProperty,
    destroyCart,
    items: state,
    wasAdded
  };

  // When state changes, handle the sessionStorage
  useEffect(() => {
    if (state.length > 0) {
      sessionStorage.setItem('cart-items', JSON.stringify(state));
    } else if (sessionStorage.getItem('cart-items')) {
      sessionStorage.removeItem('cart-items');
    }
  }, [state]);

  return (
    <CartContext.Provider value={values}>{props.children}</CartContext.Provider>
  );
}

export const CartConsumer = CartContext.Consumer;
export const useCart = () => useContext(CartContext);
