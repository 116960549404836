import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useAuthState } from '../contexts/AuthContext';
import { useFeedback } from '../contexts/FeedbackContext';

import ProductList from '../components/ProductList';
import Checkout from '../components/Checkout';
import FeedbackList from '../components/FeedbackList';

function LoggedIn({ match }) {
  const { isLoggedIn } = useAuthState();
  const { hasFeedback } = useFeedback();

  if (!isLoggedIn) {
    return <Redirect to="/logga-in" />;
  }

  if (hasFeedback) {
    return <FeedbackList />;
  }

  return <>{match.path === '/inloggad' ? <ProductList /> : <Checkout />}</>;
}

LoggedIn.propTypes = {
  match: PropTypes.object.isRequired
};

export default LoggedIn;
