import React from 'react';
import './App.scss';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import Login from './pages/Login';
import LoggedIn from './pages/LoggedIn';
import NewPassword from './pages/NewPassword';
import SitePassword from './pages/SitePassword';
import ReportSubmitted from './pages/ReportSubmitted';
import LogoutPage from './pages/LogoutPage';
import NoMatch from './pages/NoMatch';
import AppHeader from './components/AppHeader';

import AppTimer from './AppTimer';
import AuthProvider, { useAuthState } from './contexts/AuthContext';
import CartProvider from './contexts/CartContext';
import FeedbackProvider from './contexts/FeedbackContext';

// Component redirects the user depending on auth state
function IndexRedirect() {
  const { isLoggedIn } = useAuthState();

  if (isLoggedIn) {
    return <Redirect to="/inloggad" />;
  } else {
    return <Redirect to="/logga-in" />;
  }
}

/**
 * Thw app main component, harbors all contexts, routes and usual components.
 */
function App() {
  return (
    <AuthProvider>
      <FeedbackProvider>
        <CartProvider>
          <div className="App">
            <Router>
              <AppTimer>
                <AppHeader />
                <Switch>
                  <Route path="/" component={IndexRedirect} exact />
                  <Route path="/site-access" component={SitePassword} exact />
                  <Route path="/inloggad" component={LoggedIn} exact />
                  <Route path="/inloggad/varukorg" component={LoggedIn} exact />
                  <Route path="/tack" component={ReportSubmitted} exact />
                  <Route path="/logga-ut" component={LogoutPage} exact />
                  <Route path="/logga-in" component={Login} exact />
                  <Route
                    path="/logga-in/pin/:userLogin"
                    component={Login}
                    exact
                  />
                  <Route
                    path="/logga-in/pin/:userLogin/ny"
                    component={NewPassword}
                    exact
                  />
                  <Route
                    path="/logga-in/pin/:userLogin/ny/:resetKey"
                    component={NewPassword}
                    exact
                  />
                  <Route component={NoMatch} />
                </Switch>
              </AppTimer>
            </Router>
          </div>
        </CartProvider>
      </FeedbackProvider>
    </AuthProvider>
  );
}

export default App;
