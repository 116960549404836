import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export default function AuthProvider(props) {
  const cachedUser =
    JSON.parse(localStorage.getItem('authenticated-user')) || null;

  // Setup the hooks
  const [user, setUser] = useState(cachedUser);
  const [siteAuth, setSiteAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  function authSiteAccess(password = '') {
    setIsLoading(true);
    setIsError(false);
    const { REACT_APP_API_URL } = process.env;

    async function authAccess() {
      try {
        const result = await axios.post(
          `${REACT_APP_API_URL}/personalsnus/v1/site-password`,
          {
            password,
          }
        );

        if (result.data === true) {
          setSiteAuth(true);
          setIsLoading(false);
        } else {
          setSiteAuth(false);
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
      }

      setIsLoading(false);
    }

    authAccess();
  }

  // Function which fetches & logins the user
  function authenticate(username = '', password = '') {
    setIsLoading(true);
    setIsError(false);
    const { REACT_APP_API_URL } = process.env;
    async function fetchUser() {
      try {
        const result = await axios.post(
          `${REACT_APP_API_URL}/jwt-auth/v1/token?ignore`,
          {
            username,
            password,
          }
        );
        setUser(result.data);
        localStorage.setItem('authenticated-user', JSON.stringify(result.data));
      } catch (error) {
        console.error(error);
        setIsError(true);
      }

      setIsLoading(false);
    }

    fetchUser();
  }

  // Function should sign out the user, destroy the session and remove the state
  function signOut() {
    setUser(null);
    localStorage.removeItem('authenticated-user');
  }

  // Function which fetches & validates the user token
  function validate(token = '') {
    setIsLoading(true);
    setIsError(false);

    if (!token) {
      const user = JSON.parse(localStorage.getItem('authenticated-user'));
      if (user) {
        token = user.token || '';
      }
    }

    if (!token) {
      setIsLoading(false);
      setIsError(false);
      return;
    }

    async function testToken(token) {
      const { REACT_APP_API_URL } = process.env;
      try {
        const result = await axios.post(
          `${REACT_APP_API_URL}/jwt-auth/v1/token/validate`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const { data } = result.data;

        if (data.status !== 200) {
          setUser(null);
          localStorage.removeItem('authenticated-user');
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setUser(null);
        localStorage.removeItem('authenticated-user');
        setIsLoading(false);
        setIsError(true);
      }
    }

    testToken(token);
  }

  useEffect(validate, []);

  const values = {
    user,
    isLoggedIn: user && typeof user.token !== 'undefined',
    isLoading,
    isError,
    validate,
    authenticate,
    siteAuth,
    authSiteAccess,
    signOut,
  };

  return (
    <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>
  );
}

export const AuthConsumer = AuthContext.Consumer;
export const useAuthState = () => useContext(AuthContext);
