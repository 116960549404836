import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';

import './ReportSubmitted.scss';

function ReportSubmitted() {
  const [timerFinished, setTimerFinished] = useState(false);
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    let timeoutFunc = setTimeout(() => {
      setTimerFinished(true);
    }, 5000);
    return () => {
      clearTimeout(timeoutFunc);
    };
  }, [setTimerFinished]);

  useEffect(() => {
    let intervalFunc = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    return () => {
      clearInterval(intervalFunc);
    };
  }, [seconds, setSeconds]);

  return timerFinished ? (
    <Redirect to="/" />
  ) : (
    <div className="ReportSubmitted">
      <div className="wrapper">
        <h2>Tack din förbrukning är nu sparad!</h2>
        <p>
          Du är nu utloggad och kommer bli ompekad till startsidan om {seconds}{' '}
          sekunder ...
        </p>
        <p>
          Om du inte orkar vänta, <Link to="/logga-in">klicka här</Link> för att
          ta dig till startsidan.
        </p>
      </div>
    </div>
  );
}

export default ReportSubmitted;
